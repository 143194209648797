import crudMutations from "@/store/templates/crud/mutations";
import Vue from "vue";

export default {
  ...crudMutations,
  setItem(state, { index, item }) {
    Vue.set(state.model.items, index, item);
  },
  addItem(state) {
    state.tempItem.totalPrice =
      state.tempItem.unitPrice * state.tempItem.quantity;
    state.model.items.push(state.tempItem);
  },
  addTempItem(state) {
    state.tempItem = state.defaultItem;
  },
  setTempItem(state, item) {
    state.tempItem = item;
  },
  removeTempItem(state) {
    state.tempItem = null;
  },
  deleteItem(state, index) {
    state.model.items.splice(index, 1);
  },
  setVatRate(state, vatRate) {
    state.model.vatRate = vatRate;
  },
  setPdfDownloadable(state) {
    state.model.pdfDownloadable = true;
  }
};
